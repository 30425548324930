<template>
  <v-card flat>
    <v-form
      ref="historialForm"
      v-model="isValid"
      lazy-validation
    >
      <v-row class="align-center" style="min-height: 72px;">
        <v-col cols="12" sm="3" align-self="center">
          <v-select
            v-model="historialForm.tipoEstampilla"
            :items="tipos"
            label="Tipo de Estampilla"
            outlined
            dense
            :menu-props="{ maxHeight: '400' }"
            item-text="ItemName"
            item-value="ItemCode"
            :rules="[rulesRequerido]"
            class="filtros-texto custom-font-size"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="3" align-self="center">
          <v-text-field
            v-model="historialForm.numeroEstampilla"
            label="Número de Estampilla"
            outlined
            dense
            :rules="[rulesRequerido]"
            class="filtros-texto custom-font-size"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="2" align-self="center" style="margin-top: -25px;">
          <v-btn
            color="primary"
            small
            class="capitalize"
            @click="fetchRecords"
          >
            Buscar
            <v-icon right>mdi-magnify</v-icon>
          </v-btn>
        </v-col>

        <v-spacer />

        <v-col cols="auto" sm="2" class="d-flex justify-end" style="margin-top: -25px;">
          <v-btn
            color="success"
            class="capitalize"
            small
            @click="descargarReporte()"
          >
            <v-icon left>mdi-file-document</v-icon>
            Descargar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="historialHeaders"
          :items="historialDataToShow"
          class="elevation-0"
          :search="historialSearch"
        >
          <template v-slot:no-data>
            <div v-if="loading" class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
            </div>
            <div v-else>
              <div v-if="!hasSearched">
                Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores
                <v-icon right>mdi-magnify</v-icon>
              </div>

              <div v-else>
                No se encontraron resultados para la búsqueda. Revisa los campos anteriores y vuelve a intentarlo
                <v-icon right>mdi-magnify</v-icon>
              </div>
            </div>
          </template>

          <template v-slot:[`item.MovementID`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.MovementID }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.DocumentTypeDescription`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.DocumentTypeDescription }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.DocumentDate`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">

                  {{ (item.DocumentDate) ? $formatDate(item.DocumentDate, true) : '' }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.WhsCode`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.WhsCode }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.Cliente`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.Cliente }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.MovmentType`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.MovmentType }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.LegalNum`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.LegalNum }}
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <dialog-reintentar
      v-model="dialogs['descargar']"
      @input="dialog = $event"
      title="No hay archivos disponibles"
      bodyText="No se encontraron archivos para descargar. Revisa tu búsqueda e inténtalo nuevamente."
      icon="mdi-alert-circle-outline"
      textButton="Reintentar"
      color="#ffc107">
    </dialog-reintentar>
    <dialog-reintentar
      v-model="dialogs['invalidCode']"
      @input="dialog = $event"
      title="Código inválido!"
      bodyText="La información ingresada no es válida. Por favor, verifica e inténtalo nuevamente."
      icon="mdi-close"
      textButton="Reintentar"
      color="#FF5252">
    </dialog-reintentar>

  </v-card>
</template>

<script>
import DialogReintentar from "@/views/dialogs/Reintentar.vue";


export default {
  name: "HistorialEstampillasTab",
  components: {
    DialogReintentar
  },
  data() {
    return {
      tipos:[],
      isValid: false,
      loading: false,
      hasSearched: false,
      routeTiposEstampillas :"tiposEstampillas",
      rulesRequerido: (val) => !!val || "Este campo es requerido",
      historialHeaders: [
        { text: "#", value: "MovementID", sortable: false },
        { text: "Documento", value: "DocumentTypeDescription", sortable: false },
        { text: "Fecha", value: "DocumentDate", sortable: false },
        { text: "Almacén", value: "WhsCode", sortable: false },
        { text: "Detalle", value: "Cliente", sortable: false },
        { text: "Sentido", value: "MovmentType", sortable: false },
        { text: "Comprobante", value: "LegalNum", sortable: false },
      ],

      historialDataToShow: [],

      historialSearch: "",
      historialForm: {
        tipoEstampilla: null,
        numeroEstampilla: "",
      },
      tipoEstampillaItems: [],

      dialogs: {
        descargar: false,
        invalidCode:false,
      },
    };
  },
  created(){
    this.getTiposEstampillas();
  },
  methods: {
    async getTiposEstampillas(){

      await this.$axiosApi
        .getByCriteria(this.routeTiposEstampillas, this.$db("ANMAC"))
        .then((r) => {
          if (r.data) {            
            this.tipos = r.data.data.value;
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
/*
      this.tipos = [
        { name: "AC- ESTAMPILLA $ 100", id: 'AE100' },
        { name: "AC- ESTAMPILLA $ 1000", id: 'AE1M' },
        { name: "AC- ESTAMPILLA $ 50", id: 'AE50' },
        { name: "AC- ESTAMPILLA $ 2000", id: 'AE2M'},
        { name: "AC- ESTAMPILLA $ 10000", id: 'AE10M'},
        { name: "AC- ESTAMPILLA $ 200", id: 'AE200'},
        { name: "AC- ESTAMPILLA $ 300", id: 'AE300'},
        { name: "AC- ESTAMPILLA $ 50000", id: 'AE50M'},
        { name: "AC- ESTAMPILLA $ 800", id: 'AE800'},
        { name: "AC- ESTAMPILLA $ 5000", id: 'AE5M'},

      ];*/
    },

    async fetchRecords() {
      const isFormValid = this.$refs.historialForm.validate();

      if (!isFormValid) return;

      this.hasSearched = true;
      let whsCode = this.$store.state.user.sapProperties.delegInfo.U_WhsDisp;
      let delegation = whsCode.substring(0, whsCode.length - 1);
				Swal.alertGetInfo("Buscando Historial");
        console.log(this.historialForm.tipoEstampilla);
				let params = this.historialForm.tipoEstampilla+'/'+this.historialForm.numeroEstampilla+'/'+delegation
				await this.$axiosApi
					.getByCriteria("seriesReport", params)
					.then((r) => {
            console.log("r.data.data");
              console.log(r.data.data);
						if (r.data.data.length >0) {

              this.historialDataToShow = r.data.data;
							Swal.close();
						}
            else
            {
              	Swal.close();
                this.historialDataToShow = [];
                this.dialogs.invalidCode =true;
            }
					})
					.catch((error) => this.$handleAxiosError(error));

			},

      descargarReporte() {


                let url = this.$axiosApi.routes["seriesReportPdf"];

                let whsCode = this.$store.state.user.sapProperties.delegInfo.U_WhsDisp;
                let delegation = whsCode.substring(0, whsCode.length - 1);
                Swal.alertGetInfo("Generando Pdf");
                let params = this.historialForm.tipoEstampilla+'/'+this.historialForm.numeroEstampilla+'/'+delegation

                this.$axiosApi
                .sendRequestPdfLoad(url+"/"+params, "GET")
                .then((response) => {
                  Swal.close();
                  //this.isLoading = false;
                  console.log(response.data);
                  if (response.data.size <=0) {
                    this.dialogs.descargar =true ;
                    //Swal.fire("Atención!", "No se pudieron obtener los datos", "info");
                    return;
                  }
                  let blob = new Blob([response.data], {
                      type: response.data.type,
                    }),
                    url = window.URL.createObjectURL(blob);

                  window.open(url);
                })
                .catch((error) => this.$handleAxiosError(error));




    },
/*
    buscarHistorial() {
      const isFormValid = this.$refs.historialForm.validate();

      if (!isFormValid) return;

      this.hasSearched = true;

      this.historialDataToShow = this.historialDataMock;
    },
*/

  },
};
</script>

<style scoped>
.filtros-texto {
  font-size: 0.87rem !important;
}

.custom-font-size ::v-deep(.v-select__selections) {
  font-size: 12px !important;
}

.custom-font-size ::v-deep(.v-label) {
  font-size: 12px !important;
}

.table-cell-trans {
}
.capitalize {
  text-transform: capitalize;
}
</style>
