<template>
  <v-card flat>
    <v-form ref="ingresoForm" v-model="isValid" lazy-validation>
      <v-row class="align-center" style="min-height: 72px;">
        <v-col cols="12" sm="3" align-self="center">
          <v-text-field
            v-model="ingresoForm.codigoDesbloqueo"
            label="Código de Desbloqueo"
            outlined
            dense
            class="filtros-texto custom-font-size"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="2" align-self="center" style="margin-top: -25px;">
          <v-btn
            color="primary"
            small
            class="capitalize"
            @click="buscarIngreso"
          >
            Buscar
            <v-icon right>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-expansion-panels
      class="filtros-trans-container mt-3"
      v-model="panelActivo"
      multiple
      
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="primary white--text" expand-icon="mdi-menu-down"
                                  expand-icon-class="expand-icon-white">
          Dónde está mi estampilla
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div v-if="!hasSearched" class="text-center text--disabled mb-5 mt-5">
            Aún no hay datos para mostrar
          </div>
          <div v-else>
            <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px;"></div>

            <v-stepper non-linear alt-labels class="custom-stepper">
              <v-stepper-header class="justify-center align-center elevation-0">
                <v-stepper-step
                  step="1"
                  color="green"
                  :editable="false"
                  complete
                >
                  <div class="d-flex flex-column align-center text-center">
                    <strong style="white-space: nowrap;">Almacén de Tránsito</strong>
                    <div>Estado:</div>
                  </div>
                </v-stepper-step>

                <v-divider class="custom-divider"></v-divider>

                <v-stepper-step
                  step=""
                  :editable="false"
                  :complete="isIngresado"
                  :color="isIngresado ? 'green' : undefined"
                >
                  <div class="d-flex flex-column align-center text-center">
                    <strong style="white-space: nowrap;">Almacén de Ventas</strong>
                    <div>Estado:</div>
                  </div>
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </div>
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-data-table
          :headers="ingresoHeaders"
          :items="ingresoDataToShow"
          class="elevation-0"
          :search="ingresoSearch"
        >
          <template v-slot:no-data>
            <div v-if="loading" class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
            </div>
            <div v-else>
              <div v-if="!hasSearched">
                Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores
                <v-icon right>mdi-magnify</v-icon>
              </div>
              <div v-else>
                No se encontraron resultados para la búsqueda. Revisa los campos anteriores y vuelve a intentarlo
                <v-icon right>mdi-magnify</v-icon>
              </div>
            </div>
          </template>

          <template v-slot:[`item.item`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.item }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.descripcion`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left descripcion-col">
                  {{ item.descripcion }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.desde`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.desde }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.hasta`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.hasta }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.cantidad`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.cantidad }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.WhsCode`]="{ item }">
            <v-row justify="center" align="center">
              <v-col>
                <div :class="$getCssAlmacenes(item.WhsCode)" class="table-cell-trans text-center">
                  {{ getTipoAlmacen(item.WhsCode) }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.total`]="{ item }">
            <v-row>
              <v-col>
                <div class="table-cell-trans text-left">
                  {{ item.total ? '$' + $formatCurrencyValue(item.total) : '$0,00' }}
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row v-if="ingresoDataToShow.length && !isIngresado" class="mt-2">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          color="success"
          class="capitalize"
          small
          @click="dialogConfirmar"
        >
          Ingresar Estampillas
        </v-btn>
      </v-col>
    </v-row>

    <dialog-confirmar
      v-model="dialogs['ingresar']"
      @input="dialog = $event"
      title="¿Está seguro que desea ingresar las estampillas?"
      info="Esta acción no se puede deshacer."
      icon="mdi-alert-circle-outline"
      color="#ffc107"
      @confirm="ingresarEstampillas"
    >
    </dialog-confirmar>

    <dialog-validate
      v-model="dialogs['codigoInvalido']"
      @input="dialog = $event"
      title="Código inválido"
      info="La información ingresada no es válida. Por favor, verifica e inténtalo nuevamente."
      icon="mdi-alert-circle-outline"
      color="#ffc107"
    >
    </dialog-validate>
  </v-card>
</template>

<script>
import DialogValidate from "@/views/dialogs/Validate.vue";
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";

export default {
  name: "IngresoEstampillasTab",
  components: {
    DialogValidate,
    DialogConfirmar,
  },
  data() {
    return {
      routeTransfer: "stockTransfer",
      panelActivo: [],
      isValid: false,
      loading: false,
      hasSearched: false,
      ingresoForm: {
        codigoDesbloqueo: "",
      },
      isIngresado: false,
      ingresoSearch: "",
      ingresoHeaders: [
        {text: "Item", value: "item", sortable: false},
        {text: "Descripción", value: "descripcion", sortable: false},
        {text: "Desde", value: "desde", sortable: false},
        {text: "Hasta", value: "hasta", sortable: false},
        {text: "Cantidad", value: "cantidad", sortable: false},
        {text: "Tipo de Almacén", value: "WhsCode", sortable: false},
        {text: "Total", value: "total", sortable: false},
      ],

      ingresoDataMock: [
        {
          item: "AE100",
          descripcion: "AC-ESTAMPILLA$100",
          desde: "X000018",
          hasta: "X000984",
          cantidad: 967,
          WhsCode: "D00104T", // T -> Tránsito
          total: 997667.0
        },
        {
          item: "AE1M",
          descripcion: "AC-ESTAMPILLA$1000",
          desde: "C100006",
          hasta: "C110000",
          cantidad: 9995,
          WhsCode: "D00104T",
          total: 9995000.0
        },
        {
          item: "AE5M",
          descripcion: "AC-ESTAMPILLA$5000",
          desde: "X000300",
          hasta: "X000389",
          cantidad: 100,
          WhsCode: "D00104T",
          total: 500000.0
        },
      ],
      ingresoDataToShow: [],
      ingresoData: [],
      dialogs: {
        ingresar: false,
        codigoInvalido: false,
      },
    };
  },
  methods: {
    async buscarIngreso() {
      /*if (!this.ingresoForm.codigoDesbloqueo.trim()) {
        this.dialogs.codigoInvalido = true;
        this.ingresoForm.codigoDesbloqueo = "";
        return;
      }
      this.hasSearched = true;
      this.isIngresado = false;
      this.ingresoDataToShow = this.ingresoDataMock;*/
      if (!this.ingresoForm.codigoDesbloqueo.trim()) {
        this.dialogs.codigoInvalido = true;
        this.ingresoForm.codigoDesbloqueo = "";
        return;
      }

      this.loading = true;
      this.ingresoDataToShow = [];
      this.ingresoData = [];
      this.hasSearched = false;

      try {
        const docEntry = this.ingresoForm.codigoDesbloqueo.trim();
        let whsCode = this.$store.state.user.sapProperties.delegInfo.U_WhsTr;
        let criterio = docEntry+'/'+whsCode;
        let r = await this.$axiosApi.getByCriteria("inventoryTransfer", criterio);

        if (r.data && r.data.data) {
          this.ingresoData = r.data.data;
          this.ingresoDataToShow = this.mapearADataTable(r.data.data);
          
          this.hasSearched = true;
          this.isIngresado = false;
          if (this.ingresoDataToShow.length > 0) {
            let allInVentas = this.ingresoDataToShow.every(item =>
              item.WhsCode && item.WhsCode.endsWith("V")
            );
            this.isIngresado = allInVentas;
          } else {
            this.isIngresado = false;
          }
        } else {
          this.dialogs.codigoInvalido = true;
          return;
        }
      } catch (error) {
        Swal.close();
        console.log(error);
        this.dialogs.codigoInvalido = true;
      } finally {
        this.loading = false;
      }
    },

    mapearADataTable(responsePws) {
      let result = [];
      if (!Array.isArray(responsePws) || !responsePws.length) {
        return result;
      }

      responsePws.forEach(transfer => {
        if (!transfer.StockTransferLines || !transfer.StockTransferLines.length) {
          return;
        }
//console.log(transfer);
        transfer.StockTransferLines.forEach(line => {
          let serials = line.SerialNumbers || [];
          let sorted = serials.map(obj => obj.InternalSerialNumber).sort();
          let desde = sorted[0] || null;
          let hasta = sorted[sorted.length - 1] || null;
          let cantidad = sorted.length;

          let priceNumber = 0;
          if (line.ItemDescription && line.ItemDescription.includes("$")) {
            // split en el símbolo $ y tomamos la segunda parte
            let splitted = line.ItemDescription.split("$");
            if (splitted.length > 1) {
              let numericPart = splitted[1].trim();
              // Reemplazamos comas por puntos
              numericPart = numericPart.replace(",", ".");
              priceNumber = parseFloat(numericPart) || 0;
            }
          }

          let totalLine = priceNumber * cantidad;

          result.push({
            item: line.ItemCode,
            descripcion: line.ItemDescription,
            desde: desde,
            hasta: hasta,
            cantidad: cantidad,
            WhsCode: line.WarehouseCode,
            total: totalLine,
            docEntry: transfer.DocEntry,
            docNum: transfer.DocNum,
          });
        });
      });
console.log(result);
      return result;
    },

    dialogConfirmar() {
      this.dialogs.ingresar = true;
    },

    async ingresarEstampillas() {      
      let formTransfer = {};       
      const letter = "R";
      const uSeidorarDesp = "Y";    
      const now = new Date().toISOString();   
      const dataStockTransfers = this.ingresoData[0].StockTransferLines;
      const whsVenta = this.$store.state.user.sapProperties.delegInfo.U_WhsDisp
      const whsTransito = this.$store.state.user.sapProperties.delegInfo.U_WhsTr
      const poi = this.$store.state.user.sapProperties.delegInfo.U_POI;
            
      let stockTransferLines = dataStockTransfers.map((line, index) => {      
        let serials = line.SerialNumbers.map(item => ({
          InternalSerialNumber: item.InternalSerialNumber
        }));
        return {
          DocEntry: line.DocEntry,
          LineNum: index,
          ItemCode: line.ItemCode,
          Quantity: serials.length,
          SerialNumbers: serials
        };
      });
                          
      formTransfer.SAPCompany = this.$db("ANMAC");
      formTransfer.FromWarehouse = whsTransito;
      formTransfer.ToWarehouse = whsVenta;
      formTransfer.PointOfIssueCode = poi;
      formTransfer.CreationDate = now;
      formTransfer.Letter =  letter;
      formTransfer.U_SEIDORAR_DESP = uSeidorarDesp;
      formTransfer.StockTransferLines = stockTransferLines;
      Swal.alertGetInfo("Ingresando Estampillas");
      this.$axiosApi
        .post(this.routeTransfer, formTransfer)
        .then((r) => {
          Swal.close();  
          console.log(r);
          if (r.status == 200) {
            
            this.dialogs.inhabilitar = false;          
            Swal.fire({
                    icon: "success",
                    title: "Operación Exitosa",
                    text: "Se han ingresado las estampillas",
                  });
            this.ingresoData = [];
            this.ingresoDataToShow = [];
            //actualizo la lista de lotes en el componente Stock      
            this.$emit('fetchStockEstampillas');
    
          }else{
            console.log(r);
            Swal.fire({
                    icon: "error",
                    title: "Error al procesar la transferencia",
                    text: "Ha ocurrido un error al realizar el ingreso de estampillas",
                  });
          } 
        })
        .catch(function (error) {
          console.log(error);
          Swal.close();  
          Swal.fire({
                    icon: "error",
                    title: "Error al procesar la transferencia",
                    text: "Ha ocurrido un error al realizar el ingreso de estampillas",
                  });
          this.dialogs.inhabilitar = false;         
        });           
    },

    /*async ingresarEstampillas() {            
      if (!this.ingresoDataToShow.length) {
        // No hay nada que transferir
        return;
      }

      const fromWarehouse = this.ingresoDataToShow[0].WhsCode;
      const toWarehouse = fromWarehouse.replace("T", "V");
      //const pointOfIssueCode = "00101"; //hardcodeado
      const pointOfIssueCode = this.$store.state.user.sapProperties.delegInfo.U_POI
      

      const letter = "R";
      const uSeidorarDesp = "Y";

      let stockTransferLines = this.ingresoDataToShow.map((line, index) => {

       

        const serials = data.map(item => ({
          InternalSerialNumber: item.DistNumber
      }));

        return {
          DocEntry: line.docEntry,
          LineNum: index,
          ItemCode: line.item,
          Quantity: line.cantidad,
          SerialNumbers: serialObjects
        };
      });

      console.log(stockTransferLines);

      const nowIso = new Date().toISOString();
      const body = {
        ToWarehouse: toWarehouse,
        FromWarehouse: fromWarehouse,
        PointOfIssueCode: pointOfIssueCode,
        CreationDate: nowIso,
        Letter: letter,
        U_SEIDORAR_DESP: uSeidorarDesp,
        StockTransferLines: stockTransferLines
      };

      try {
        this.loading = true;
        const response = await this.$axiosApi.post("inventoryTransferPost", body);

        if (response.data && response.data.data) {
          console.log("Transferencia completada:", response.data.data);

          this.isIngresado = true;

          this.ingresoDataToShow = this.ingresoDataToShow.map((line) => ({
            ...line,
            WhsCode: toWarehouse
          }));
        } else {
          console.error("Error: Respuesta vacía o sin 'data'");
        }

      } catch (error) {
        console.error("Error en la transferencia:", error);
      } finally {
        this.loading = false;
      }
    },*/

    getTipoAlmacen(whsCode) {
      if (whsCode.endsWith('V')) return 'Venta';
      if (whsCode.endsWith('C')) return 'Control';
      if (whsCode.endsWith('T')) return 'Tránsito';
    }
  },
};
</script>

<style scoped>
.filtros-texto {
  font-size: 0.87rem !important;
}

.custom-font-size ::v-deep(.v-select__selections) {
  font-size: 0.87rem !important;
}

.custom-font-size ::v-deep(.v-label) {
  font-size: 0.87rem !important;
}

.descripcion-col {
  width: 300px;
  word-wrap: break-word;
}

.filtros-trans-container {
}

.custom-stepper {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  max-width: 700px;
  margin: auto;
  padding: 10px;
}

.custom-divider {
  flex-grow: 1;
  border-bottom: 2px solid #ccc !important;
  margin: 0 16px;
}

::v-deep(.v-stepper-step__step) {
  display: none !important;
}

::v-deep .v-expansion-panel-header__icon .v-icon {
  color: white !important;
}

.table-cell-trans {
}

.capitalize {
  text-transform: capitalize;
}
</style>
