<template>
  <v-card flat>
    <v-row class="px-2">
      <v-col cols="12">
        <v-switch
          v-model="toggleLotes"
          label="Inhabilitación por Lotes"
          class="d-inline-block"
        ></v-switch>
      </v-col>
    </v-row>

    <v-form ref="inhabilitacionForm" v-model="isValid" lazy-validation>
      <v-row class="align-center" style="min-height: 50px;">
        <v-col cols="12" sm="3" align-self="center">
          <v-select
            v-model="inhabilitacionForm.tipoEstampilla"
            :items="tipos"
            label="Tipo de Estampilla"
            outlined
            dense
            :menu-props="{ maxHeight: '400' }"
            item-text="ItemName"
            item-value="ItemCode"
            :rules="[rulesRequerido]"
            class="filtros-texto custom-font-size"
          ></v-select>
        </v-col>
        <template v-if="toggleLotes">
          <v-col cols="12" sm="3" align-self="center">
            <v-text-field
              v-model="inhabilitacionForm.numeroSerieInicio"
              label="Número de Serie Inicio"
              outlined
              dense
              :rules="[rulesRequerido]"
              class="filtros-texto custom-font-size"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" align-self="center">
            <v-text-field
              v-model="inhabilitacionForm.numeroSerieFin"
              label="Número de Serie Fin"
              outlined
              dense
              :rules="[rulesRequerido]"
              class="filtros-texto custom-font-size"
            ></v-text-field>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" sm="3" align-self="center">
            <v-text-field
              v-model="inhabilitacionForm.numeroEstampilla"
              label="Número de Estampilla"
              outlined
              dense
              :rules="[rulesRequerido]"
              class="filtros-texto custom-font-size"
            ></v-text-field>
          </v-col>
        </template>
        <v-col cols="12" sm="2" align-self="center" style="margin-top: -25px;">
          <v-btn
            color="primary"
            small
            class="capitalize"
            @click="fetchRecords()"
          >
            Buscar
            <v-icon right>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12">
        <template>
          <v-data-table
            :headers="(!toggleLotes)?headers:headersLote"
            :items="inhabilitacionDataToShow"
            class="elevation-0"
            :search="inhabilitacionSearch"
          >
            <template v-slot:no-data>
              <div v-if="loading" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mx-auto"
                ></v-progress-circular>
              </div>
              <div v-else>
                <div v-if="!hasSearched">
                  Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores
                  <v-icon right>mdi-magnify</v-icon>
                </div>
                <div v-else>
                  No se encontraron resultados para la búsqueda. Revisa los campos anteriores y vuelve a intentarlo
                  <v-icon right>mdi-magnify</v-icon>
                </div>
              </div>
            </template>
            <template v-slot:[`item.ItemCode`]="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.ItemCode }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.ItemName`]="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.ItemName }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.DistNumber`]="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.DistNumber }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.From`]="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.From }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.To`]="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.To }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.TotalQuantity`]="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.TotalQuantity }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </template>        
      </v-col>
    </v-row>

    <v-row v-if="inhabilitacionDataToShow.length" class="mt-2">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          color="red"
          class="capitalize"
          small
          @click="dialogConfirmar"
        >
          Inhabilitar
        </v-btn>
      </v-col>
    </v-row>

    <dialog-confirmar
      v-model="dialogs.inhabilitar"
      @input="dialog = $event"
      title="¿Está seguro que desea inhabilitar las estampillas?"
      info="Esta acción moverá las estampillas del almacén de ventas al almacén de control. Esta acción no se puede deshacer."
      icon="mdi-alert-circle-outline"
      color="#ffc107"
      @confirm="inhabilitarEstampillas()"
    >
    </dialog-confirmar>
  </v-card>
</template>

<script>
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import Swal from "sweetalert2";

export default {
  name: "InhabilitacionEstampillasTab",
  components: {
    DialogConfirmar
  },
  data() {
    return {
      route: "stockByWarehouse",
      routeTransfer: "stockTransfer",
      routeTiposEstampillas :"tiposEstampillas",
      desserts: [],
      filterKey: [],
      selectedHeaders: [],
      almacenes: [],
      tipos:[],
      loading: false,
      queryString:'',
      formSearch:{},
      filters: {
      },
      loadingTipos: false, // Estado de carga
      isValid: false,
      loading: false,
      hasSearched: false,
      toggleLotes: false,
      inhabilitacionForm: {
        tipoEstampilla: null,
        numeroEstampilla: "",
        numeroSerieInicio: "",
        numeroSerieFin: ""
      },
      rulesRequerido: (val) => !!val || "Este campo es requerido",      
      headers: [
        { text: "Item", filterable: true, value: "ItemCode", sortable: false },
        { text: "Descripción", filterable: true, value: "ItemName", sortable: false },
        { text: "Número de Serie", value: "DistNumber", sortable: false }
      ],      
      headersLote: [
        { text: "Item", value: "ItemCode", sortable: false },
        { text: "Descripción", value: "ItemName", sortable: false },
        { text: "Desde", value: "From", sortable: false },
        { text: "Hasta", value: "To", sortable: false },
        { text: "Cantidad", value: "TotalQuantity", sortable: false }
      ],
      
      
      inhabilitacionDataToShow: [],
      serialItems:[],
      inhabilitacionSearch: "",
      dialogs: {
        inhabilitar: false
      }
    };
  },
  computed: {

    filteredData() {
      return this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  methods: {
    async getTiposEstampillas(){
      await this.$axiosApi
        .getByCriteria(this.routeTiposEstampillas, this.$db("ANMAC"))
        .then((r) => {
          if (r.data) {
            console.log(r.data.data.value);
            this.tipos = r.data.data.value;
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
        /*
      this.tipos = [
        { name: "AC- ESTAMPILLA $ 100", id: 'AE100' },
        { name: "AC- ESTAMPILLA $ 1000", id: 'AE1M' },
        { name: "AC- ESTAMPILLA $ 50", id: 'AE50' },
        { name: "AC- ESTAMPILLA $ 2000", id: 'AE2M'},
        { name: "AC- ESTAMPILLA $ 10000", id: 'AE10M'},
        { name: "AC- ESTAMPILLA $ 200", id: 'AE200'},
        { name: "AC- ESTAMPILLA $ 300", id: 'AE300'},
        { name: "AC- ESTAMPILLA $ 50000", id: 'AE50M'},
        { name: "AC- ESTAMPILLA $ 800", id: 'AE800'},
        { name: "AC- ESTAMPILLA $ 5000", id: 'AE5M'},
        
      ];*/
    },
    setQueryParams(){
      let queryParams = {};

      if(this.formSearch.almacen) {
        queryParams.warehouse = this.formSearch.almacen.id;
      }

      if(this.formSearch.tipo_estampilla) {
        if(this.formSearch.tipo_estampilla.id) {
          queryParams.tipo = this.formSearch.tipo_estampilla.id;
        }
      }
      queryParams.UserListPrice = this.$store.state.user.sapProperties.PriceListNum;

      this.queryString = new URLSearchParams(queryParams).toString();
    },
    async fetchRecords() {
      const isFormValid = this.$refs.inhabilitacionForm.validate();

      if (!isFormValid) return;      
      this.hasSearched = true;
      let whsCode = this.$store.state.user.sapProperties.delegInfo.U_WhsDisp;
      let tipo = this.inhabilitacionForm.tipoEstampilla;      
      Swal.alertGetInfo("Buscando Estampillas");
      let route = "itemBySerie";
      let params = this.inhabilitacionForm.numeroEstampilla+'/'+whsCode+'/'+tipo      
      if(this.toggleLotes){
        route = "itemsByLote";        
        params = this.inhabilitacionForm.numeroSerieInicio+'/'+this.inhabilitacionForm.numeroSerieFin+'/'+whsCode+'/'+tipo;        
      }
      
      await this.$axiosApi
        .getByCriteria(route, params)
        .then((r) => {
          if (r.data.data) {
            //console.log(r.data.data);
            if(!this.toggleLotes){
              this.serialItems = r.data.data;
              this.inhabilitacionDataToShow = r.data.data;
            }
            else{
              this.inhabilitacionDataToShow = r.data.data.batchItems;
              this.serialItems = r.data.data.serialItems;
            }							
            Swal.close();
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
		},
    
    dialogConfirmar() {
      if (this.inhabilitacionDataToShow.length === 0) return;
      this.dialogs.inhabilitar = true;
    },
    inhabilitarEstampillas() {
      //console.log("this.serialItems");
      //console.log(this.serialItems);
      let serials = Array();
      let formTransfer = {}; 
      let cantEstampillas = 1;     
    if(!this.toggleLotes){
      serials = [{InternalSerialNumber: this.serialItems[0].DistNumber}];
    }
    else{
      const data = this.serialItems;
      serials = data.map(item => ({
          InternalSerialNumber: item.DistNumber
      }));
      
      cantEstampillas = serials.length;
    }
      
      console.log(serials);
      let whsVenta = this.$store.state.user.sapProperties.delegInfo.U_WhsDisp
      let whsControl = this.$store.state.user.sapProperties.delegInfo.U_WhsNoDisp
      let poi = this.$store.state.user.sapProperties.delegInfo.U_POI;
      formTransfer.SAPCompany = this.$db("ANMAC");
      formTransfer.FromWarehouse = whsVenta;
      formTransfer.ToWarehouse = whsControl;
      formTransfer.PointOfIssueCode = poi;
      formTransfer.StockTransferLines = [{
        ItemCode: this.serialItems[0].ItemCode,
        ItemName: this.serialItems[0].ItemName,
        Quantity: cantEstampillas,
        //SerialNumbers:[{InternalSerialNumber: this.serialItems[0].DistNumber}]
        SerialNumbers: serials
      }];
      Swal.alertGetInfo("Inhabilitando Estampillas");
      this.$axiosApi
        .post(this.routeTransfer, formTransfer)
        .then((r) => {
          Swal.close();  
          
          if (r.status == 200) {
            this.resetForm();            
            this.dialogs.inhabilitar = false;          
            Swal.fire({
                    icon: "success",
                    title: "Operación Exitosa",
                    text: "Se ha deshabilitado la estampilla",
                  });
            //actualizo la lista de lotes en el componente Stock      
            this.$emit('fetchStockEstampillas');
    
          }else{
            Swal.fire({
                    icon: "error",
                    title: "Error al procesar la transferencia",
                    text: "Ha ocurrido un error al deshabilitar la estampilla",
                  });
          }     
        })
        .catch(function (error) {
          Swal.close();  
          this.dialogs.inhabilitar = false;
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

         
        });
      

      /** payload de ejemplo */
            /*

            {
              "SAPCompany": "TST_ANMACV20",
              "FromWarehouse": "D00101V",
              "ToWarehouse": "D00101C",
              "PointOfIssueCode": "00101",
              "StockTransferLines": [
                {
                
                  
                  
                  "ItemCode": "AE1M",
                  "ItemName": "AC- ESTAMPILLA $ 1000",
                  "Quantity": 1,
                  
                  "SerialNumbers ": [
                    {
                        "InternalSerialNumber ": "C158134"
                    }
                  ]
                }
              ]
            }

            {
              "SAPCompany": "TST_ANMACV20",
              "FromWarehouse": "D00101V",
              "ToWarehouse": "D00101C",
              "PointOfIssueCode": "00101",
              "StockTransferLines": [
                {
                
                  "WarehouseCode": "D00101C",
                  "FromWarehouseCode": "D00101V",                  
                  "ItemCode": "AE1M",
                  "ItemName": "AC- ESTAMPILLA $ 1000",
                  "Quantity": 1,                  
                  "SerialNumbers ": [
                    {
                        "InternalSerialNumber ": "C158133"
                    }
                  ]
                }
              ]
            }
            {
              "SAPCompany": "TST_ANMACV20",
              "FromWarehouse": "D00101V",
              "ToWarehouse": "D00101C",
              "PointOfIssueCode": "00101",
              "StockTransferLines": [
                {
                
                  "WarehouseCode": "D00101C",
                  "FromWarehouseCode": "D00101V",
                  "BaseEntry": 0,
                  "BaseLine": 0,
                  "BaseType": 0,
                  "ItemCode": "AE1M",
                  "ItemName": "AC- ESTAMPILLA $ 1000",
                  "Quantity": 1,
                  "U_SEIDORAR_DistNuD": "Y",
                  "U_SEIDORAR_DistNuH": "Y",
                  "SerialNumbers ": [
                    {
                        "InternalSerialNumber ": "C158131 "
                    }
                  ]
                }
              ]
            }
            */

     
      // Resetear formulario
    //  this.resetForm();
    },
    resetForm() {
      // Reinicia los campos del formulario
      this.inhabilitacionForm = {
        tipoEstampilla: null,
        numeroEstampilla: "",
        numeroSerieInicio: "",
        numeroSerieFin: ""
      };

      if (this.$refs.inhabilitacionForm) {
        this.$refs.inhabilitacionForm.resetValidation();
      }

      this.inhabilitacionDataToShow = [];
      this.hasSearched = false;
    },
  },
  watch: {
    toggleLotes(newValue) {
      this.resetForm();
    }
  },
  async created(){
    try {
    // Verifica si sapProperties está vacío
      if (Object.values(this.$store.state.user.sapProperties).length === 0) {
        // Realiza la acción de inicio de sesión
        this.signIn();
        // Espera 3 segundos antes de proceder
        await this.$delay(3000);
      }
      console.log("this.$store.state.user.sapProperties.delegInfo");
      console.log(this.$store.state.user.sapProperties.delegInfo);      
      // Configura encabezados seleccionados
      this.selectedHeaders = this.headers;
      //siempre se busca en el almacen de ventas del usuario     
      this.getTiposEstampillas();
      console.log(this.tipos);
      
    } catch (error) {
      console.error("Error en created():", error);
    }
  },
};
</script>

<style scoped>
.filtros-texto {
  font-size: 0.87rem !important;
}

.custom-font-size ::v-deep(.v-select__selections) {
  font-size: 0.87rem !important;
}

.custom-font-size ::v-deep(.v-label) {
  font-size: 0.87rem !important;
}

.table-cell-trans {
}

.capitalize {
  text-transform: capitalize;
}
</style>
