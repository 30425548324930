<template>
  <v-container
    id="crud"
    fluid
    tag="section"
    class="px-6"
  >
    <v-tabs
      v-model="activeTab"
    >
      <v-tabs-slider color="#0078D4"></v-tabs-slider>

      <v-tab
        class="capitalize"
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-slider color="#0078D4"></v-tabs-slider>
      <!-- Contenido de las pestañas -->
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>

                <v-row>
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="formSearch.tipo_estampilla"
                      :items="tipos"
                      label="Tipo de Estampilla"
                      class="filtros-trans"
                      outlined
                      dense
                      :append-icon="formSearch.tipo_estampilla ? 'mdi-close' : 'mdi-menu-down'"
                      @click:append="clearTipoEstampilla"
                      @click="loadItems"

                      :menu-props="{ maxHeight: '400' }"
                      item-text="name"
                      item-value="id"
                      return-object

                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3">

                        <v-select
                          v-model="formSearch.almacen"
                          :items="almacenes"
                          class="filtros-trans"
                          outlined
                          dense
                          item-chip
                          item-text="name"
                          item-value="id"
                          return-object
                        >
                        <template v-slot:selection="{ item }">
                          <v-chip color="primary" dark>
                            {{ item.name }}
                          </v-chip>
                        </template>
                      </v-select>



                  </v-col>


                  <v-col cols="12" sm="2" class="d-flex align-center">
                    <v-btn
                      color="primary"
                      small
                      class="capitalize"
                      @click="fetchRecords()"
                        >Buscar
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="2" class="d-flex align-right">
                    <v-btn color="success" class="capitalize" small
                    @click=" descargarReporte()">

                      <v-icon left>mdi-file-document</v-icon>
                      Descargar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" md="12" class="">
                    <v-data-table
                      :headers="showHeaders"
                      :items="desserts"
                      :search="search"
                      sort-by="secret"
                      class="elevation-0"
                    >
                      <template v-slot:no-data>
                        <div v-if="loading" class="text-center">
                          <v-progress-circular
                            v-if="loading"
                            indeterminate
                            color="primary"
                            class="mx-auto"
                          ></v-progress-circular>
                        </div>
                        <div v-else>
                          Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores<v-icon right>mdi-magnify</v-icon>
                        </div>
                      </template>

                      <template v-slot:[`item.ItemCode`]="{ item }">
                        <v-row>
                          <v-col class="table-cell-trans">
                            <a
                              href="#"
                              class="text-primary text-decoration-underline"
                              @click.prevent="goToDetalle(item)"
                            >
                              {{ item.ItemCode }}
                            </a>
                          </v-col>

                        </v-row>
                      </template>
                      <template v-slot:[`item.ForeignName`]="{ item }">
                        <v-row>
                          <v-col>
                            <div class="table-cell-trans text-left">
                              {{ item.ForeignName }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.From`]="{ item }">
                        <v-row>
                          <v-col>
                            <div class="table-cell-trans text-left">
                              {{ item.From }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.To`]="{ item }">
                        <v-row>
                          <v-col>
                            <div class="table-cell-trans text-left">
                              {{ item.To }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.QuantityTotal`]="{ item }">
                        <v-row>
                          <v-col>
                            <div class="table-cell-trans text-left">
                              {{ item.QuantityTotal }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.WhsCode`]="{ item }">
                        <v-row>
                          <v-col>
                            <div :class="$getCssAlmacenes(item.WhsCode)" class="table-cell-trans">
                              {{ item.WhsCode }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.PriceTotal`]="{ item }">
                        <v-row>
                          <v-col>
                            <div class="table-cell-trans text-left">
                              {{ (item.PriceTotal) ? '$'+$formatCurrencyValue(item.PriceTotal) : '$0,00' }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>

                      <!--
                      <template v-slot:[`item.WhsCode`]="{ item }">
                        <v-row>
                          <v-col>
                            <div :class="$getCss(item.WhsCode)" class="table-cell-trans">
                              {{ item.WhsCode }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      -->

                      <!--
                      <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-center align-center">
                          <div style="width: 25px; display: flex; justify-content: center;">
                              <v-icon
                                v-if="item.Drafts && item.Drafts.length > 0"
                                small color="warning" title="Ver Borrador" @click="viewFactura(item, 'borrador')">
                                mdi-file-document
                          </v-icon>
                          </div>
                          <div style="width: 25px; display: flex; justify-content: center;">
                            <v-icon
                              v-if="item.Invoices && item.Invoices.length > 0"
                              small color="primary" title="Ver Factura" @click="viewFactura(item, 'borrador')">
                              mdi-file-document
                            </v-icon>
                          </div>
                        </div>
                      </template>
                      -->
                    </v-data-table>
                  </v-col>
                </v-row>

          </v-card>
        </v-tab-item>
        <v-tab-item>
          <historial-estampillas-tab />
        </v-tab-item>
        <v-tab-item>
          <ingreso-estampillas-tab @fetchStockEstampillas="handleChildEvent" />
        </v-tab-item>
        <v-tab-item>
          <inhabilitacion-estampillas-tab @fetchStockEstampillas="handleChildEvent"  />
        </v-tab-item>
      </v-tabs-items>
  </v-container>
</template>

<script>
function title() {
  return "Transacciones";
}
import { mapState, mapActions, mapMutations } from "vuex";
import HistorialEstampillasTab from "./HistorialEstampillasTab.vue";
import IngresoEstampillasTab from "./IngresoEstampillasTab.vue";
import InhabilitacionEstampillasTab from "./InhabilitacionEstampillasTab.vue";

export default {
  name: "StockEstampillas",
  components: {
    HistorialEstampillasTab,
    IngresoEstampillasTab,
    InhabilitacionEstampillasTab
  },
  data: (vm) => ({
    title: title(),
    route: "stockByWarehouse",
    activeTab: 0,
    today: new Date().toISOString().substr(0, 10),
    search: "",
    users: false,
    items:["Lotes", "Historial de estampilla", "Ingreso de estampilla", "Inhabilitación de estampillas"],
    headers: [
      { text: "Item", filterable: true, value: "ItemCode", sortable: false },
      { text: "Descripción", filterable: true, value: "ForeignName", sortable: false },
      { text: "Desde", filterable: true, value: "From", sortable: false },
      { text: "Hasta", filterable: true, value: "To", sortable: false },
      { text: "Cantidad", filterable: true, value: "QuantityTotal", sortable: false },
      { text: "Tipo de Almacén", filterable: true, value: "WhsCode", sortable: false },
      { text: "Total", filterable: true, value: "PriceTotal", sortable: false },

    ],
    desserts: [],
    filterKey: [],
    selectedHeaders: [],
    almacenes: [],
    tipos:[],
    loading: false,
    queryString:'',
    formSearch:{},
    filters: {
    },
    loadingTipos: false, // Estado de carga

  }),

  computed: {

    filteredData() {
      return this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  watch: {

  },

  mounted() {
    console.log("Componente " + this.title + " creado");
  },

  async created() {
  try {
    // Verifica si sapProperties está vacío
    if (Object.values(this.$store.state.user.sapProperties).length === 0) {
      // Realiza la acción de inicio de sesión
      this.signIn();
      // Espera 3 segundos antes de proceder
      await this.$delay(3000);
    }
    console.log("this.$store.state.user.sapProperties.delegInfo");
    console.log(this.$store.state.user.sapProperties.delegInfo);
    let delegInfo = this.$store.state.user.sapProperties.delegInfo;
    this.almacenes = [
      {name: "Control", id: delegInfo.U_WhsNoDisp},
      {name: "Tránsito", id: delegInfo.U_WhsTr},
      {name: "Venta", id: delegInfo.U_WhsDisp},
    ];

    // Configura encabezados seleccionados
    this.selectedHeaders = this.headers;
    this.formSearch.almacen = {name: "Venta", id: delegInfo.U_WhsDisp};
    this.fetchRecords();
  } catch (error) {
    console.error("Error en created():", error);
  }
},

  methods: {
    handleChildEvent() {      
      this.$initializeByQuery(false);
    },
    loadItems() {
      if (this.tipos.length === 0) {
        // Simulando una llamada a una API para cargar los items
        //this.loadingTipos = true;
        setTimeout(() => {
          let items = this.desserts;
          const extractedData = items.map(item => ({
            name: item.ForeignName,
            id: item.ItemCode,
          })).sort((a, b) => a.name.localeCompare(b.ForeignName));

          this.tipos = extractedData;
          //this.loadingTipos = false;
        }, 1000); // Simulando una demora en la carga
      }
    },
    descargarReporte() {

      let url = this.$axiosApi.routes["stockPdf"];

      this.setQueryParams();


      if(this.queryString)
      {
        url = url +"?" +this.queryString
      }
      Swal.alertGetInfo("Buscando información");
      this.$axiosApi
        .sendRequestPdfLoad(url, "GET")
        .then((response) => {
          Swal.close();
          //this.isLoading = false;
          if (response.data.size <= 0) {
            Swal.fire("Atención!", "No se pudieron obtener los datos", "info");
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch((error) => this.$handleAxiosError(error));
    },

    ...mapActions({
      signIn: "login",
    }),

    goToDetalle(item) {
      console.log(item);
    },

    setQueryParams(){

       let queryParams = {};

        if(this.formSearch.almacen) {
          queryParams.warehouse = this.formSearch.almacen.id;
        }

        if(this.formSearch.tipo_estampilla) {
          if(this.formSearch.tipo_estampilla.id) {
            queryParams.tipo = this.formSearch.tipo_estampilla.id;
          }

        }

        queryParams.UserListPrice = this.$store.state.user.sapProperties.PriceListNum;

       this.queryString = new URLSearchParams(queryParams).toString();
    },

    fetchRecords(){
      this.setQueryParams();
      //Filtros
      this.$initializeByQuery(true);
    },
    clearAlmacen() {
      console.log(this.formSearch.almacen);
      this.formSearch.almacen = {name: null, id: null};
      console.log(this.formSearch.almacen);
    },
    clearTipoEstampilla() {
      console.log('clear tipo estampilla')
      this.formSearch.tipo_estampilla = {name: null, id: null};
    },

  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>

<style scoped>
.underline-text {
  text-decoration: underline;
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize; /* Convierte la primera letra de cada palabra en mayúscula */
}

</style>
